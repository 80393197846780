<template>
  <div class="margin-top-bottom-twenty">
    <div class="nav-header border-radius-6 box-shadow-light-grey margin-top-bottom-twenty">
      <el-menu
        :default-active="defaultActive"
        mode="horizontal"
        @select="handleSelect"
        router
      >
        <!-- <el-menu-item index="/novel-put-management/novelProjectManager/novel-today/ad-group" class='font-eighteen'> 今日广告 </el-menu-item>
      <el-menu-item index="/novel-put-management/novelProjectManager/novel-today/put-data" class='font-eighteen'> 投放计划 </el-menu-item> -->
        <el-menu-item
          class="font-eighteen margin-right-23"
          v-for="item in novelPutMenu2"
          :key="item.path"
          :index="item.path"
        >
          <span slot="title">{{ item.title }}</span>
        </el-menu-item>
      </el-menu>
    </div>
    <!-- <put-data ref="put" :params="params.putData || {}" v-if="curTab === '1'" />
    <ad-group
      ref="adGroup"
      :params="params.advert || {}"
      v-if="curTab === '2'"
    ></ad-group> -->
    <div>
      <router-view :customParams="params" v-if="flag"></router-view>
    </div>
  </div>
</template>

<script>
import { novelPutMenu2 } from '@/assets/js/nav.js'
// import putData from './put'
// import adGroup from './ad-group'
import { formatTime } from '@/assets/js/utils'

const timer = null
export default {
  name: 'Recharge',
  props: {
    params: Object,
    selected: Number
  },

  data () {
    return {
      defaultActive: this.$route.meta.submenu,
      curTab: '2',
      novelPutMenu2,
      flag: false
    }
  },
  // beforeRouteEnter(to, from, next) {
  //   next(()=> {
  //     this.init()
  //   })
  // },
  beforeRouteLeave (to, from, next) {
    if (timer) {
      this.$refs.put.clearTimer()
      this.$refs.advert.clearTimer()
    }
    next()
  },
  watch: {
    $route: {
      handler (newV) {
        this.defaultActive = newV.meta.submenu || newV.path
      }
    }
  },
  mounted () {
    this.init()
  },
  methods: {
    init () {
      this.$set(
        this.params,
        'putData',
        this.params.putData
          ? this.params.putData
          : {
            selectTime: [
              formatTime(Date.now(), 'yyyy-MM-dd'),
              formatTime(Date.now(), 'yyyy-MM-dd')
            ]
          }
      )
      this.flag = true
      // this.$refs.adGroup.initData()
    },
    handleSelect (info) {
      this.curTab = info
    }
  },
  components: {
    // putData,
    // adGroup
  }
}
</script>

<style lang="scss" scoped>
// /deep/ .el-menu-item {
//   height: 52px;
//   line-height: 52px;
// }
// /deep/ .el-menu .is-active {
//   background: #fff !important;
// }
.nav-header {
  box-sizing: border-box;
  padding:0 5px;
  background:#fff;
  /deep/ .el-menu-item,
  .el-submenu__title {
    height: 34px;
  }
  /deep/ .el-menu {
    height: 52px !important;
    display: flex;
    align-items: center;
    .el-menu-item {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  /deep/ .is-active{
    background:#fff !important;
  }
}
</style>
